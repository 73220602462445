
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>
          SUBWORLD
        </h1>
        <br></br>
        <p>
          coming soon
        </p>

      </header>
    </div>
  );
}

export default App;
